import scrollToElement from "@ecom/ui/utils/scrollToElement"
import pushToDataLayer from "@ecom/ui/utils/pushToDataLayer"

export default function scrollToForm(e: React.MouseEvent) {
  const form = document.getElementById("app")

  if (form) {
    pushToDataLayer({
      event: "buttonClick",
      name: e.currentTarget.textContent,
      placement: "main_banner",
      actionType: "anchor",
      actionDestination: "form",
      label: "Scroll to form",
    })

    scrollToElement(form)
  }
}
