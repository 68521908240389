import React from "react"
import { Link } from "gatsby"
import logo from "./img/logo.png"

type Props = { className?: string }

const Logo: React.FC<Props> = ({ className }) => (
  <Link to="/" className={className}>
    <img src={logo} alt="" />
    <span>Улучшение Кредитной Истории</span>
  </Link>
)

export default Logo
