import React from "react"

import ThemeProvider from "@material-ui/styles/ThemeProvider"
import { blueTheme } from "@ecom/ui/styles/themes/blue"

import { SEO, SEOProps } from "../seo"

export interface BaseLayoutProps extends SEOProps {
  children: React.ReactNode
}

export default function Layout({ children, title, description }: BaseLayoutProps) {
  return (
    <ThemeProvider theme={blueTheme}>
      <SEO title={title} description={description} />
      {children}
    </ThemeProvider>
  )
}
